import React, {useEffect, useState} from 'react';
import { useTranslation } from "react-i18next"

import ProductTemplate from '../../templates/productTemplate';


const ProductList = () => {
  const { t } = useTranslation();

  // useEffect(() => {
  //   window.dataLayer = window.dataLayer || []
  //   window.dataLayer &&
  //   window.dataLayer.push({
  //     pageURL: "/product-1",
  //     testovani: websiteVersion,
  //   })
  // }, [websiteVersion])

  return (
  <>
    <li>{t("home.program2.productListItem1")}</li>
    <li>{t("home.program2.productListItem2")}</li>
    <li>{t("home.program2.productListItem3")}</li>
    <li>{t("home.program2.productListItem4")}</li>
  </>
  )
}

const Product1 = () => {
  const { t } = useTranslation();
  return (
  <ProductTemplate
    productId="1"
    title={t("home.program2.program1title")}
    productList={ProductList}
  />
  )
  }

export default Product1
